body {
  min-height: 100vh;
}

.site-grid-container {
  min-height: 100vh;
  display: grid;

  grid-template-columns:  [gutter-left] 1fr
                          [content-col] 960px
                          [gutter-right] 1fr;

  @media (max-width: 1000px) {
    grid-template-columns:  [gutter-left] 2ch
                            [content-col] 1fr
                            [gutter-right] 2ch;
  }

  grid-template-rows: [header-top] 10rem
                    [header] 150px
                    [interaction-box] 3rem
                    [navigation-box] 4rem
                    [content] minmax(0, auto)
                    [footer] 2rem [end-row];

  @media (max-width: 700px) {
    grid-template-rows: [header-top] 4rem
                    [header] 110px
                    [interaction-box] 0
                    [navigation-box] 4rem
                    [content] minmax(0, auto)
                    [footer] 2rem [end-row];
  }


  .banner-background {
    //grid-area: header-top / gutter-left / navigation-box / end-col;
    grid-area: header-top / gutter-left / content / end-col;
  }

  .navigation-background {
    grid-area: navigation-box / gutter-left / content / end-col;
  }

  header.site-header {
    grid-area: header / content-col / header / gutter-right;
  }

  .navigation-container {
    grid-area: navigation-box / content-col / navigation-box / gutter-right;
  }

  main {
    grid-area: content / content-col / content / gutter-right;
  }

  footer.site-footer {
    grid-area: footer / content-col / footer / gutter-right;
  }
}
