.banner-background {
  position: relative;
  z-index: -1;

  background-color: $banner-bg;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url("/assets/images/shelves.jpg");
  background-size: cover;
  background-position: center;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    backdrop-filter: blur(1px);
  }
}

.navigation-background {
  //background-color: $banner-bg;
  //backdrop-filter: blur(10px);
}

.navigation-container {
}

nav {
  height: 100%;
  font-family: Fredoka;
  font-weight: 400;
  font-size: 0.8em;
  text-transform: lowercase;

  & > ul {
    display: flex;
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  $nav-link: white;
  $nav-link-hover-bg: rgba(100, 100, 100, 0.2);
  $nav-link-active-bg: rgba(100, 100, 100, 0.5);

  ul li {
    padding-left: 2ch;
    // &:first-child {
    //   padding-left: 0;
    // }
    @media (max-width: 700px) {
      padding-left: 0;
    }
    padding-right: 2ch;

    &:hover {
      background-color: $nav-link-hover-bg;
    }

    a {
      height: 100%;
      display: flex;
      align-items: center;

      color: $nav-link;
      text-decoration: none;
      font-size: 1.5em;

      .text {
        margin-left: 0.5ch;
      }

      &:hover {
        text-decoration: none;
        color: $nav-link;

        // span.text {
        //   border-bottom: 2px dashed $nav-link;
        // }
      }
    }

    &.selected {
      background-color: $nav-link-active-bg;

      a {
        color: $nav-link;

        // span.text {
        //   border-bottom: 2px solid $nav-link;
        // }
      }
    }
  }
}

header.site-header {
  text-shadow: 1px 1px 10px rgba(6, 6, 6, .4);

  .subtitle {
    color: $banner-fg-accent;

    margin: 0;

    font-family: Inconsolata;
    font-weight: 300;

    line-height: 2.5;

    text-transform: lowercase;
  }

  .brand {
    margin: 0;
    line-height: 0.8;

    color: $banner-fg;

    font-family: Fredoka;
    font-weight: 400;
  }
}

.logo-container {
  display: inline-block;
  &:before {
    border-radius: 50%;
    height: 100%;
  }
}

.logo-svg {
  path {
    //fill: $banner-fg;
    fill: white;
    //stroke: white;
    filter: drop-shadow(1px 1px 10px rgba(6, 6, 6, .4));
  }
}
