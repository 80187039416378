@import "config/_colour";
@import "mixins/_responsive";

@import "layout/_site_layout";
@import "layout/_siteheader_layout";
@import "layout/_footer_layout";
@import "layout/_default_layout";

@import "components/_header";
@import "components/_body_text";
@import "components/_default_layout";
@import "components/_sidebar_contact";
@import "components/_table_membership";

@font-face {
    font-family: Hacked;
    src: url("/assets/fonts/HACKED.ttf") format("truetype");
}

@font-face {
    font-family: Oswald;
    font-weight: 500;
    src: url("/assets/fonts/Oswald-Medium.ttf") format("truetype");
}

@font-face {
    font-family: Oswald;
    font-weight: 400;
    src: url("/assets/fonts/Oswald-Regular.ttf") format("truetype");
}

@font-face {
    font-family: Oswald;
    font-weight: 300;
    src: url("/assets/fonts/Oswald-Light.ttf") format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
