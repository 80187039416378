header.site-header .siteheader-layout {
  display: flex;
  height: 100%;

  @media (min-width: 1100px) {
    margin-left : -3ch;
  }

  .logo-container {
    margin-top: -2em;
    margin-bottom: -1em;

    flex: 0 0 calc( 210px + 2ch );
    @media (max-width: 700px) {
      flex: 0 0 calc( 85px + 2ch );
    }
    @media (max-width: 400px) {
      display: none;
    }
    .logo-svg {
      height: 100%;
      width: 100%;
      margin-left: -1ch;
    }
  }

  .brand {
    font-size: calc(120px - 40px);

    @media (max-width: 700px) {
      font-size: calc(60px - 20px);
    }
  }

  .subtitle {
    font-size: 30px;
    @media (max-width: 700px) {
      font-size: 19px;
    }
  }

  .title-group {
    align-self: center;
    margin-top: -8px;
    @media (min-width: 701px) {
      margin-left: 1ch;
    }
  }
}
