body.default-layout:not(.fullbleed) main {
  .content-grid-container {
    display: grid;
    grid-template-columns:  [content-col] 60ch
                            [content-gutter] 5ch
                            [aside-col] 1fr;

    grid-template-rows: [content] minmax(0, auto);

    @media (max-width: 1000px) {
      grid-template-columns:  [content-col] 3fr
                              [content-gutter] 5ch
                              [aside-col] 2fr;
    }

    @media (max-width: 750px) {
      grid-template-columns:  [content-col] 1fr
                              [content-gutter] 0
                              [aside-col] 0;

      grid-template-rows: [content] minmax(0, auto)
                         [content-gutter] 2rem
                         [aside] minmax(0, auto);
    }

  }

  .content {
    grid-area: content / content-col / content / content-gutter;
  }

  .aside-container {
    display: flex;
    grid-area: content / aside-col / content / aside-col;
    flex-direction: column;
    gap: 1em;
    padding-top: 1em;
    @media (max-width: 750px) {
      grid-area: aside / content-col / aside / content-gutter;
      flex-direction: row;
      padding-top: 0;
      justify-content: flex-start;
    }
    aside {
      @media (max-width: 750px) {
        width: 100%;
      }
    }
  }
}
