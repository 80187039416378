body.default-layout main {
  .banner-image {
    background-position: center;
  }

  .aside-container aside {
    border: 1px solid lightgray;
    border-radius: 20px;

    padding: 20px;

    strong {
      font-family: Fredoka;
      font-weight: 500;
    }

    strong .fas, .fa {
      padding-right: 5px;
    }

    p {
      margin: 0.5em 0;
      line-height: 1.15;
    }
  }
}
