table.membership-fees {
  border-collapse: collapse;
  td {
    border: 1px solid grey;
    padding: 1ch;
  }
  ul {
    padding: 0;
    list-style-type: none;
    li {
      padding: 0.25em 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  a.signup {
    font-weight: bold;
  }
}
