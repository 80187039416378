hr {
  border-style: none;
  border-top: 2px solid $fg-color;
}

body {
  background-color: $bg-color;
  color: $fg-color;
  font-family: Lato;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

h1, h2 {
  font-family: Fredoka;
  font-weight: 400;
}

blockquote {
  font-size: 1.5em;
  font-family: Fredoka;
  line-height: 1.15;
  font-weight: 400;
  @media (max-width: 700px) {
    margin: 1em 1ch;
  }
}

.announcement {
  margin-top: 2em;
  padding: 2em;
  border-radius: 10px;

  background-color: $grey;

  h1 {
    margin: 0px;
  }
}

ul, ol {
  @media (max-width: 700px) {
    padding-inline-start: 2ch;
  }
}

dl {
  dt {  
    font-weight: bold;
    margin-top: 0.5em;
  }
  dd {
    margin-left: 0;
  }
}

a {
  color: $link;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.sidebar-map {
  height: 25vh;
  border-radius: 10px;
  //box-shadow: 0px 0px 7px darken($bg-color, 10%);
  //border: 5px solid $bg-color;
}

.map {
  height: 50vh;
  border-radius: 10px;
}
