$primary: #153B50;
$secondary: #1A5974;
$accent: #D66853;
$grey: #E7ECEF;

$bg-color: #FBFBFB;
$fg-color: #001021;

$banner-bg: $grey;
$banner-fg: $primary;
$banner-fg-accent: $fg-color;
$banner-fg: white;
$banner-fg-accent: white;

$link: $secondary;
